import pcoficina1 from "../../assets/img/Computadoras/PCOFICINA1.png";
import pcoficina2 from "../../assets/img/Computadoras/PCOFICINA2.png";
import pcoficina3 from "../../assets/img/Computadoras/PCOFICINA3.png";
import pcoficina4 from "../../assets/img/Computadoras/PCOFICINA4.png";
import pcoficina5 from "../../assets/img/Computadoras/PCOFICINA5.png";
import pcoficina6 from "../../assets/img/Computadoras/PCOFICINA6.png";/*
import pcoficina7 from "../../assets/img/Computadoras/PCOFICINA7.png";
import pcoficina8 from "../../assets/img/Computadoras/PCOFICINA8.png";*/
import { Link } from "react-router-dom";



export default function SectionOtros() {
  const products = [
    { id: 1, description: "PC OFICINA RYZEN 3", price: "S/1499", image: pcoficina6 },
    { id: 2, description: "PC OFICINA INTEL 3", price: "S/1699", image: pcoficina4 },
    { id: 3, description: "PC DISEÑO RYZEN 5", price: "S/1799", image: pcoficina5 },
    { id: 4, description: "PC DISEÑO INTEL 5", price: "S/1999", image: pcoficina3 },
    { id: 5, description: "PC INGENIERIA INTEL 5", price: "S/2199", image: pcoficina2 },
    { id: 6, description: "PC INGENIERIA RYZEN 5", price: "S/2399", image: pcoficina1 },/*
    { id: 8, description: "COMBO WUKONG ", price: "S/3799", image: pcgamer7 },
    { id: 7, description: "COMBO VALORANT JETT ", price: "S/1399", image: pcgamer8 },*/
    
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <a href="#">
                    <img
                      className="w-full h-[300px] object-contain rounded"
                      src={product.image}
                      alt={product.description}
                    />
                  </a>
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                <Link
                  to={'/DetallePcOficina'} // Redirige a la página de detalles del producto
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition text-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
