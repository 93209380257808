import Header from "components/home/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import Incentives from "components/home/Incentives";
import SectionMonitores from "components/home/SectionMonitores";
import Ofertas from "components/home/Ofertas";
import SectionComputadoras from "components/home/SectionComputadoras";
import SectionOtros from "components/home/SectionOtros";
import CTA from "components/home/CTA";
import LogoCloud from "components/home/LogoCloud";
import BlogList from "components/home/BlogList";
import MarcasPopulares from "components/home/MarcasPopulares";

function Home() {
  return (
    <Layout>
      <Navbar />
      <div className="pt-28">
        <Header />
        <Incentives />
        <SectionMonitores />
        <Ofertas />
        <SectionComputadoras />
        <SectionOtros />
        {/* <CTA />
        <LogoCloud />
        <BlogList /> */}
      </div>
      <Footer />
    </Layout>
  );
}
export default Home;
