import { connect } from "react-redux";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import loading_docs from "assets/img/loading_docs.gif";
import logo_fnk from "assets/img/logo_fenk.png";
import SyncLoader from "react-spinners/SyncLoader";

function Navbar() {
  const [loading, setLoading] = useState(true);

  window.onscroll = function () {
    scrollFuntion();
  };

  function scrollFuntion() {
    if (document.getElementById("navbard")) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("navbar").classList.add("shadow-navbar");
        document.getElementById("navbar").classList.add("bg-white");
      } else {
        document.getElementById("navbar").classList.remove("shadow-navbar");
        document.getElementById("navbar").classList.remove("bg-white");
      }
    }
  }

  return (
    <nav
      id="navbar"
      className="w-full py-2 top-0 transition duration-300 ease-in-out z-40 bg-white fixed"
    >
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to="/" className="ml-4 mt-2">
            <img
              src={logo_fnk}
              width={250}
              height={240}
              className=""
              alt="logo de la empresa"
            />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <NavLink
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="text-lg inline-flex font-medium leading-6 text-gray-900 border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4"
            >
              Inicio
            </NavLink>
            <NavLink
              to="/laptops"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="text-lg inline-flex font-medium leading-6 text-gray-900 border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4"
            >
              Laptops
            </NavLink>
            <NavLink
              to="/Computadoras"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="text-lg inline-flex font-medium leading-6 text-gray-900 border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4"
            >
              Computadoras
            </NavLink>
            <NavLink
              to="/Componentes"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="text-lg inline-flex font-medium leading-6 text-gray-900 border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4"
            >
              Componentes
            </NavLink>
            <NavLink
              to="/perifericos"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="text-lg inline-flex font-medium leading-6 text-gray-900 border-b-2 border-white hover:border-red-500 transition duration-300 ease-in-out mx-4"
            >
              Perifericos
            </NavLink>

            <Link
              to="/Nosotros"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              className="inline-flex ml-12 items-center rounded-md border border-transparent bg-red-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-black transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            >
              Sobre Nosotros
              <SyncLoader
                className="ml-3 -mr-1 h-4 w-8"
                loading={loading}
                size={6}
                speedMultiplier={0.5}
                color="#ffffff"
              />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Navbar);
