import { NavLink } from "react-router-dom";

function CaseCard({ data, index }) {
  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="flex-shrink-0">
      <NavLink
  to={index === 0 ? "/PcGamer" : "/PcOficina"} // RUTA
  onMouseEnter={() => {
    const title_element = document.getElementById(index);
    title_element.style.color = '#ef0008'; 
    const img = document.getElementById(data.id);
    img.classList.add('scale-110'); // AUMENTO
  }}
  onMouseLeave={() => {
    const title_element = document.getElementById(index);
    title_element.style.color = 'black'; 
    const img = document.getElementById(data.id);
    img.classList.remove('scale-110'); // REMUEVE
  }}
>
  <img
    id={data.id}
    className="h-96 w-full transition duration-400 ease-in-out object-cover rounded-lg"
    src={data.imageUrl}
    alt={data.title}
  />
</NavLink>

      </div>

      <div className="flex flex-1 flex-col justify-between bg-white p-6">
        <div className="flex-1">
          <p className="text-xl font-medium text-gray-800">
            <a href={data.category.href} className="hover:underline">
              {data.category.name}
            </a>
          </p>

          <NavLink to={data.href} className="mt-2 block">
            <p
              id={index}
              className="lg:text-4xl pt-4 pb-6 text-2xl font-semibold transition duration-400 ease-in-out text-gray-900"
            >
              {data.title}
            </p>
            <p className="mt-3 text-2xl space-y-2 leading-9 text-gray-500">
              {data.description}
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default CaseCard;
