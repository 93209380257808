import { Link } from "react-router-dom";
import laptop1 from "../../assets/img/LAPTOPS/LAPTOP1.png";
import laptop2 from "../../assets/img/LAPTOPS/LAPTOP2.png";
import laptop3 from "../../assets/img/LAPTOPS/LAPTOP3.png";
import laptop4 from "../../assets/img/LAPTOPS/LAPTOP4.png";
import laptop5 from "../../assets/img/LAPTOPS/LAPTOP5.png";
import laptop6 from "../../assets/img/LAPTOPS/LAPTOP6.png";

export default function SectionOtros() {
  const products = [
    {
      id: 1,
      description: "DELL INSPIRON 3250 CORE I5 1235U RAM 8GB DDR4, SSD 512GB",
      price: "S/1750",
      image: laptop1,
    },
    {
      id: 2,
      description: "LENOVO V15 RYZEN 5 7520U RAM 8GB DDR5, SSD 256GB",
      price: "S/1750",
      image: laptop2,
    },
    {
      id: 3,
      description: "HP 15 - FC0071LA RYZEN 5 7520U RAM 8GB DDR5, SSD 512GB",
      price: "S/1950",
      image: laptop3,
    },
    {
      id: 4,
      description: "LENOVO V15 CORE I3 1215U RAM 18GB DDR4, SSD 512GB",
      price: "S/1490",
      image: laptop4,
    },
    {
      id: 5,
      description: "ASUS E15044G CORE I3 - N305 RAM 8GB DDR4, SSD 512GB",
      price: "S/1490",
      image: laptop5,
    },
    {
      id: 6,
      description: "LENOVO CORE I5 15IRH8 LOQ RAM 8GB DDR5, SSD 512GB",
      price: "S/3200",
      image: laptop6,
    },
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <img
                    className="w-full h-[300px] object-contain rounded"
                    src={product.image}
                    alt={product.description}
                  />
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                {/* Aquí agregamos el botón para redirigir al detalle del producto */}
                <Link
                  to={'/DetalleLaptop'} // Redirige a la página de detalles del producto
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition text-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
