import laptop from "../../assets/img/CATEGORIAS/LAPTOPS.png";
import computadora from "../../assets/img/CATEGORIAS/COMPUTADORAS.png";
import componentes from "../../assets/img/CATEGORIAS/COMPONENTES.png";
import perifericos from "../../assets/img/CATEGORIAS/PERIFERICOS.png";
import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";

// Lista de categorías
const categories = [
  {
    imageSrc: laptop,
    link: "/Laptops",
  },
  {
    imageSrc: computadora,
    link: "/Computadoras",
  },
  {
    imageSrc: componentes,
    link: "/Componentes",
  },
  {
    imageSrc: perifericos,
    link: "/Perifericos",
  },
];

export default function Categorias() {
  return (
    <div className="bg-gray-50 py-20">
      {/* Sección de Categorías */}
      <div className="mx-auto max-w-5xl px-6 py-0 text-center sm:px-6 lg:px-8">
        <div className="container mx-auto px-0">
          <div className="text-center max-w-2xl mx-auto mb-8">
            <h1 className="text-4xl font-bold tracking-tight text-gray-800 sm:text-6xl">
              <span></span>
              <span className="text-black">
                <Typewriter
                  words={[
                    " CATEGORIAS",
                    " LAPTOPS",
                    "COMPUTADORAS",
                    "COMPONENTES",
                    "PERIFERICOS",
                  ]}
                  loop={0}
                  cursor
                  cursorStyle="_"
                  typeSpeed={100}
                  deleteSpeed={100}
                  delaySpeed={1000}
                />
              </span>
            </h1>
          </div>
          {/* Grid de categorías */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {categories.map((category, index) => (
              <Link
                key={index}
                to={category.link}
                className="group block transition"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <div className="flex flex-col items-center">
                  <img
                    src={category.imageSrc}
                    alt={category.name}
                    className="w-90 h-70 object-cover rounded-lg mb-4 transition-transform transform hover:scale-105"
                  />
                  <h4 className="text-lg font-semibold text-black group-hover:text-red-500">
                    {category.name}
                  </h4>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
