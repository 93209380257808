import { useState, useEffect } from "react";
import mousecobra from "../../assets/img/mousecobra.png";

export default function Features() {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateEndOfMonth = () => {
      const now = new Date();
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Último día del mes
      endOfMonth.setHours(23, 59, 59, 999); // Fin del día
      return endOfMonth;
    };

    const countdownDate = calculateEndOfMonth();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate.getTime() - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonte
  }, []);

  return (
    <div className="bg-white-100 py-20">
      <div className="container mx-auto px-8 py-10 border-double border-4 border-gray-100">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/2">
            <div className="overflow-hidden rounded-lg">
              <img
                src={mousecobra} // Se mantiene el nombre original
                alt="Mouse Gamer Cobra"
                className="w-full h-auto object-cover"
              />
            </div>
          </div>

          <div className="w-full lg:w-1/2 flex flex-col justify-center px-6">
            <span className="text-sm font-bold text-green-600 uppercase tracking-wide mb-2">
              Hasta fin de mes 15% Dcto
            </span>
            <h3 className="text-3xl font-bold text-gray-800 mb-4">
              MOUSE GAMER COBRA, DPI AJUSTABLE, RGB
            </h3>
            <p className="text-gray-600 mb-2">
              Este mouse es ideal para gamers, con sensibilidad ajustable y
              retroiluminación RGB.
            </p>
            <p className="text-gray-600 mb-4">Stock: 150 │ ID: 5021</p>
            <div className="flex items-center space-x-4 mb-6">
              <span className="text-2xl font-bold text-gray-800">S/89</span>
              <del className="text-lg text-gray-500">S/105</del>
            </div>

            <div className="bg-gray-200 rounded-lg p-4 mb-6">
              <div className="flex justify-between text-center">
                <div className="flex flex-col">
                  <h2 className="text-2xl font-bold text-gray-800">
                    {timeLeft.days}
                  </h2>
                  <span className="text-sm text-gray-600">Days</span>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl font-bold text-gray-800">
                    {timeLeft.hours}
                  </h2>
                  <span className="text-sm text-gray-600">Hours</span>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl font-bold text-gray-800">
                    {timeLeft.minutes}
                  </h2>
                  <span className="text-sm text-gray-600">Minutes</span>
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl font-bold text-gray-800">
                    {timeLeft.seconds}
                  </h2>
                  <span className="text-sm text-gray-600">Seconds</span>
                </div>
              </div>
            </div>

            <div>
              <a
                href={`https://wa.me/51991842137?text=${encodeURIComponent(
                  "Hola, estoy interesado en el producto: MOUSE GAMER COBRA, DPI AJUSTABLE, RGB. ¿Está disponible?"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white text-sm px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center"
              >
                ¡COMPRAR AHORA!
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
