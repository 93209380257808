import mousegamer from "../../assets/img/mousegamer.png";
import mouse1 from "../../assets/img/PERIFERICOS/mouse1.1.png";
import mouse2 from "../../assets/img/PERIFERICOS/mouse2.1.png";
import mouse3 from "../../assets/img/PERIFERICOS/mouse3.1.png";

export default function SectionOtros() {
  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Producto 1 */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Logitech</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <img
                  className="w-full h-auto rounded"
                  src={mouse1}
                  alt="Mouse Logitech"
                />
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2 text-gray-800">
                  MOUSE LOGITECH G203 RGB BLACK G203
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/130</span>
                </div>
              </div>
              <button
                className="bg-black text-white text-sm px-4 py-2 rounded-lg hover:bg-gray-800 mt-4 w-full"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza al inicio de la página
                  window.location.href = "/DetalleMouse"; // Redirige a la ruta especificada
                }}
              >
                Ver más
              </button>
            </div>
          </div>

          {/* Producto 2 */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Redragon</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <img
                  className="w-full h-auto rounded"
                  src={mouse2}
                  alt="Mouse Redragon"
                />
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2 text-gray-800">
                  MOUSE REDRAGON COBRA BLACK M711
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/89</span>
                </div>
              </div>
              <button
                className="bg-black text-white text-sm px-4 py-2 rounded-lg hover:bg-gray-800 mt-4 w-full"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza al inicio de la página
                  window.location.href = "/DetalleMouse"; // Redirige a la ruta especificada
                }}
              >
                Ver más
              </button>
            </div>
          </div>

          {/* Producto 3 */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Antryx</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <img
                  className="w-full h-auto rounded"
                  src={mouse3}
                  alt="Mouse Antryx"
                />
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2 text-gray-800">
                  MOUSE ANTRYX CHROME STORM KURTANA
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/99</span>
                </div>
              </div>
              <button
                className="bg-black text-white text-sm px-4 py-2 rounded-lg hover:bg-gray-800 mt-4 w-full"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza al inicio de la página
                  window.location.href = "/DetalleMouse"; // Redirige a la ruta especificada
                }}
              >
                Ver más
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
