import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";

function Nosotros() {
  return (
    <Layout>
      <Navbar />
      <div className="pt-36 px-4 sm:px-6 md:px-8">
        {/* Hero Section */}
        <div className="relative bg-gradient-to-r from-red-600 to-black text-white rounded-lg shadow-lg mb-12">
          <div className="max-w-7xl mx-auto text-center py-16">
            <h1 className="text-4xl sm:text-5xl font-bold mb-4 uppercase">
              Sobre Nosotros
            </h1>
            <p className="text-lg sm:text-xl">
              Somos JC FENK COMPUTER, una empresa peruana comprometida con la
              excelencia tecnológica. Descubre quiénes somos y lo que nos mueve.
            </p>
          </div>
        </div>

        {/* Misión y Visión Section */}
        <div className="max-w-7xl mx-auto text-center mb-16">
          <h2 className="text-3xl sm:text-4xl font-semibold text-black mb-12">
            Nuestra Misión y Visión
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Misión */}
            <div className="bg-white p-8 rounded-lg shadow-lg border-b-4 border-red-600 transform transition duration-500 hover:scale-105">
              <h3 className="text-xl font-semibold text-black mb-4">Misión</h3>
              <p className="text-gray-700">
                Realizar servicios con seriedad y excelencia en los mercados
                tecnológicos e informáticos, consolidándonos como una marca
                confiable para clientes y empresas.
              </p>
            </div>

            {/* Visión */}
            <div className="bg-white p-8 rounded-lg shadow-lg border-b-4 border-red-600 transform transition duration-500 hover:scale-105">
              <h3 className="text-xl font-semibold text-black mb-4">Visión</h3>
              <p className="text-gray-700">
                Ser una empresa líder en soluciones tecnológicas, reconocida por
                nuestra calidad, innovación y compromiso con el cliente.
              </p>
            </div>
          </div>
        </div>

        {/* Valores Section */}
        <div className="bg-gray-50 w-full min-h-screen py-8">
          <div className="text-center">
            <h2 className="text-3xl sm:text-4xl font-semibold text-black mb-12">
              Nuestros Valores
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-6">
              {[
                {
                  icon: "fa-handshake",
                  title: "Respeto",
                  text: "Valoramos las opiniones y derechos de todos.",
                },
                {
                  icon: "fa-clipboard-check",
                  title: "Compromiso",
                  text: "Cumplimos con nuestras responsabilidades y metas.",
                },
                {
                  icon: "fa-star",
                  title: "Excelencia",
                  text: "Buscamos siempre la calidad en todo lo que hacemos.",
                },
                {
                  icon: "fa-shield-alt",
                  title: "Integridad",
                  text: "Actuamos con honestidad y ética.",
                },
                {
                  icon: "fa-user-shield",
                  title: "Confianza",
                  text: "Generamos seguridad en nuestros clientes.",
                },
                {
                  icon: "fa-users",
                  title: "Compañerismo",
                  text: "Fomentamos el trabajo en equipo.",
                },
                {
                  icon: "fa-gavel",
                  title: "Transparencia",
                  text: "Actuamos con claridad y honestidad.",
                },
                {
                  icon: "fa-handshake",
                  title: "Colaboración",
                  text: "Trabajamos juntos para alcanzar metas comunes.",
                },
              ].map((value, index) => (
                <div
                  key={index}
                  className="bg-white p-8 rounded-lg shadow-lg border-t-4 border-red-600 transform transition duration-500 hover:scale-105"
                >
                  <div className="text-red-600 text-4xl mb-4">
                    <i className={`fas ${value.icon}`}></i>
                  </div>
                  <h4 className="text-xl font-bold text-black mb-2">
                    {value.title}
                  </h4>
                  <p className="text-gray-600">{value.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export default Nosotros;
