import { Typewriter } from "react-simple-typewriter";
import { Link } from "react-router-dom";
import { useState } from "react";
import imagen1 from "../../assets/img/BANNERS/BANNER.png";
import imagen2 from "../../assets/img/BANNERS/imagen2.jpg";
import imagen3 from "../../assets/img/BANNERS/imagen3.jpg";
import imagen4 from "../../assets/img/BANNERS/imagen4.jpg";

const images = [imagen1, imagen2, imagen3, imagen4]; 
function Header() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <main className="bg-gray-50">
      {/* <div className="mx-auto max-w-full pt-20 pb-10 text-center">
        <h1 className="text-4xl font-bold tracking-tight text-gray-800 sm:text-6xl">
          Fenk{" "}
          <Typewriter
            words={[" Calidad", "Seguridad", "Innovación", "Confianza"]}
            loop={0}
            cursor
            cursorStyle="_"
            typeSpeed={100}
            deleteSpeed={100}
            delaySpeed={1000}
          />
        </h1>
        <ul className="flex justify-center gap-6 py-6">
            {["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"].map(
              (item, index) => (
                <li key={index}>
                  <Link
                    to={`/servicios/${item.toLowerCase().replace(" ", "-")}`}
                    className="text-lg font-medium text-gray-700 hover:text-red-500 border-b-2 border-transparent hover:border-red-500 transition"
                  >
                    {item}
                  </Link>
                </li>
              )
            )}
          </ul>
      </div> */}
      <div className="relative mx-auto max-w-8xl">
        <div className="overflow-hidden rounded-lg shadow-lg">
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="w-full h-[400px] md:h-[500px] lg:h-[600px] object-cover object-center"
          />
        </div>
        {/* Botones del slider */}
        <button
          className="absolute top-1/2 left-4 -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700"
          onClick={prevSlide}
        >
          &#8249;
        </button>
        <button
          className="absolute top-1/2 right-4 -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700"
          onClick={nextSlide}
        >
          &#8250;
        </button>
      </div>
      <div>
        <div className="bg-gray-100 py-8 mt-5">
          <div className="container mx-auto px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Feature 1 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                  <i className="fas fa-truck"></i>
                  </div>
                  <h4 className="text-xl font-bold mb-2">Envios Provincia</h4>
                  <p className="text-gray-600">a Nivel Nacional</p>
                  </div>


              {/* Feature 2 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                  <i className="fas fa-check"></i>
                </div>
                <h4 className="text-xl font-bold mb-2">Garantía</h4>
                <p className="text-gray-600">1 año</p>
              </div>

              {/* Feature 3 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                  <i className="fas fa-wallet"></i>
                </div>
                <h4 className="text-xl font-bold mb-2">Transferencias</h4>
                <p className="text-gray-600">Todos los Bancos sin costo</p>
              </div>

              {/* Feature 4 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                  <i className="fas fa-headset"></i>
                </div>
                <h4 className="text-xl font-bold mb-2">Tarjetas +5%</h4>
                <p className="text-gray-600">Aceptamos Visa Mastercard</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Header;
