import compo1 from "../../assets/img/COMPONENTES/fuente1.png";
import compo2 from "../../assets/img/COMPONENTES/fuente2.png";
import compo3 from "../../assets/img/COMPONENTES/fuente3.png";
import compo4 from "../../assets/img/COMPONENTES/placa1.png";
import compo5 from "../../assets/img/COMPONENTES/placa2.png";
import compo6 from "../../assets/img/COMPONENTES/placa3.png";
import compo7 from "../../assets/img/COMPONENTES/procesador1.png";
import compo8 from "../../assets/img/COMPONENTES/procesador2.png";
import compo9 from "../../assets/img/COMPONENTES/procesador3.png";
import compo10 from "../../assets/img/COMPONENTES/refrigeracion1.png";
import compo11 from "../../assets/img/COMPONENTES/refrigeracion2.png";
import compo12 from "../../assets/img/COMPONENTES/refrigeracion3.png";
import { Link } from "react-router-dom";




export default function SectionOtros() {
  const products = [
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo1 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo2 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo4 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo3 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo5 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo6 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo7 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo8 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo9 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo10 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo11 },
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image:  compo12 },
    

    



 
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <a href="#">
                    <img
                      className="w-full h-[300px] object-contain rounded"
                      src={product.image}
                      alt={product.description}
                    />
                  </a>
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                <Link
                  to={'/DetalleComponente'} // Redirige a la página de detalles del producto
                  className="mt-4 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition text-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
