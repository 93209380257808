import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

import Error404 from "containers/erros/Error404";
import Home from "containers/pages/Home";
import Laptops from "containers/pages/Laptops";
import Periferico from "containers/pages/Perifericos";
import Nosotros from "containers/pages/Nosotros";
import Componentes from "containers/pages/Componentes";
import Computadoras from "containers/pages/Computadoras";
import PcGamer from "containers/pages/PcGamer";
import PcOficina from "containers/pages/PcOficina";
import DetalleLaptop from "containers/pages/DETALLES/DetalleLaptop";
import DetalleMouse from "containers/pages/DETALLES/DetalleMouse";
import DetallePcGamer from "containers/pages/DETALLES/DetallePcGamer";
import DetallePcOficina from "containers/pages/DETALLES/DetallePcOficina";
import DetalleComponente from "containers/pages/DETALLES/DetalleComponente";
import DetallePeriferico from "containers/pages/DETALLES/DetallePeriferico";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Error Display */}
          <Route path="*" element={<Error404 />} />
          {/* Home Display */}
          <Route path="/" element={<Home />} />
          <Route path="/laptops" element={<Laptops />} />
          <Route path="/pcgamer" element={<PcGamer />} />
          <Route path="/pcoficina" element={<PcOficina />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/componentes" element={<Componentes />} />
          <Route path="/computadoras" element={<Computadoras />} />
          <Route path="/perifericos" element={<Periferico />} />
          <Route path="/detallelaptop" element={<DetalleLaptop />} />
          <Route path="/detallemouse" element={<DetalleMouse />} />
          <Route path="/detallepcgamer" element={<DetallePcGamer />} />
          <Route path="/detallepcoficina" element={<DetallePcOficina />} />
          <Route path="/detallecomponente" element={<DetalleComponente />} />
          <Route path="/detalleperiferico" element={<DetallePeriferico />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
