import CaseCard from "./CaseCard";
import pcgamer from '../../assets/img/CATEGORIAS/PC GAMER.png';
import pcoficina from '../../assets/img/CATEGORIAS/PC OFICINA.png';

function CasesList() {

  const posts = [
    {
      id: '1234-qwer',
      title: 'PC PARA GAMERS',
      href: '#',
      category: { name: '', href: '#ef0008' },
      description:
        'PCs de alto rendimiento diseñadas para jugar los títulos más exigentes. Cuentan con potentes tarjetas gráficas, procesadores rápidos y excelente refrigeración para una experiencia de juego fluida y sin interrupciones.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      imageUrl: pcgamer,
      readingTime: '6 min',
    },
    {
      id: '1234-asdf',
      title: 'PC DE OFICINA',
      href: '#',
      category: { name: '', href: '#' },
      description:
        'Equipos optimizados para tareas diarias como navegar, escribir y gestionar correos. Ofrecen un rendimiento confiable para trabajo administrativo y productividad sin necesidad de componentes de alto rendimiento.',
      date: 'Mar 10, 2020',
      datetime: '2020-03-10',
      imageUrl: pcoficina,
      readingTime: '4 min',
    },
  ];

  return (
    <div className="relative px-4 pt-8 pb-20 sm:px- lg:px-8 lg:pt-1 lg:pb-10">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-full">
        <div className="mx-auto xl:mx-12 mt-12 grid max-w-lg gap-40 lg:max-w-none lg:grid-cols-2">
          {posts.map((post, index) => (
            <CaseCard index={index} data={post} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CasesList;
